import { css } from '@emotion/react'
import { Button } from '@mantine/core'
import React, { memo, useEffect, useState } from 'react'
import { apirc } from '~/configs/apirc'
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss'

export const Daddy960FreeTrialButton = memo<ReactProps>(function Daddy960FreeTrialButton() {
  const [readyTrial, setReadyTrial] = useState(false)
  const [countdown, setCountdown] = useState(5)

  useEffect(() => {
    const timer = setInterval(() => {
      setReadyTrial(false)
      setCountdown(5)
    }, 5000)
    return () => clearTimeout(timer)
  }, [readyTrial])

  useEffect(() => {
    const clicked = readyTrial === true && countdown > 0
    const count = setTimeout(() => clicked && setCountdown(countdown - 1), 1000)
    return () => clearTimeout(count)
  }, [countdown, readyTrial])

  return (
    <>
      {readyTrial === false && (
        <Button
          css={css`
            ${fill_horizontal_all_center}
            width: 260px;
            height: 36px;
            color: #333333;
            background-color: #ffc641;
          `}
          onClick={() => setReadyTrial(true)}
        >
          點擊按鈕開始7天試用
        </Button>
      )}
      {readyTrial === true && (
        <Button
          css={css`
            ${fill_horizontal_all_center};
            width: 260px;
            height: 36px;
            color: #333333;
          `}
          onClick={() => {
            apirc.me.api.postFreeTrial()
            window.location.reload()
          }}
          color='yellow'
        >
          確定啟用? ({countdown}秒後取消)
        </Button>
      )}
    </>
  )
})
