import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Avatar } from '@mantine/core'
import { useRouter } from 'next/router'
import { memo } from 'react'
import { fontWeight500 } from '~/css/font'
import useMedia from '~/hooks/useMedia'
import {
  fill_horizontal_all_center,
  fill_vertical_all_center,
} from '~/modules/AppLayout/FlexGridCss'
import { useFirebaseMeState } from '~/modules/auth/containers/useFirebaseMeState'
import { useMeStore } from '~/modules/SDK/me/useMeStore'
import { Daddy960FreeTrialButton } from '~/pages/daddy960_opkevin/component/Daddy960FreeTrialButton'
import { fadeIn } from './OptionChart'
import { useDaddy960Trial } from './useDaddy960Tutorial'

const GoogleLoginView = memo<ReactProps>(function GoogleLoginView() {
  const auth = useFirebaseMeState.useContainer()
  const router = useRouter()

  return (
    <googleButtonStyled.button
      onClick={() => {
        auth.acts.loginWithGoogle().then(() => {
          router.reload()
        })
      }}
    >
      <googleButtonStyled.logo src='google-icon.png' />
      會員登入 / 開始試用
    </googleButtonStyled.button>
  )
})

export const UnregisteredView = memo<ReactProps<{ product: 'opkevin' | 'stocknerve' | 'goodway' }>>(
  function UnregisteredView(props) {
    const firebaseMe = useFirebaseMeState.useContainer()
    const meUserState = useMeStore(state => state.meUserState)

    const trial = useDaddy960Trial(props.product)

    /** 會員名稱 */
    const userName = meUserState?.name ?? '-'
    /** 會員代碼 */
    const userNumber = useMeStore(state => state.meUserState?.code)
    /** 會員信箱 */
    const email = useMeStore(state => state.meUserState?.email)
    const photoUrl = useMeStore(state => state.meUserState?.photo)

    /** 會員大頭貼 */
    const userPicture = (
      <Avatar
        src={photoUrl}
        size={72}
        radius='lg'
        alt="it's me"
      />
    )
    return (
      <div
        css={css`
          ${fill_vertical_all_center};
          gap: 8px;
        `}
      >
        {userPicture}
        <infoStyleds.item>{userName}</infoStyleds.item>
        <infoStyleds.itemTitle>會員代碼</infoStyleds.itemTitle>
        <infoStyleds.item>{userNumber}</infoStyleds.item>
        <infoStyleds.itemTitle>Email信箱</infoStyleds.itemTitle>
        <infoStyleds.item>{email}</infoStyleds.item>

        <infoStyleds.toolTipContent>
          <p>權限尚未開通</p>
          <p>聯繫客服專員</p>
        </infoStyleds.toolTipContent>

        {trial.state.newUser === true ? <Daddy960FreeTrialButton /> : '您已無試用權限'}
        <infoStyleds.logoutButton onClick={() => firebaseMe.acts.logout()}>
          登出
        </infoStyleds.logoutButton>
      </div>
    )
  },
)

export const Daddy960LoginView = memo<
  ReactProps<{
    faviconUrl: string
    lineLink: string
    product: 'opkevin' | 'stocknerve' | 'goodway'
  }>
>(function Daddy960LoginView(props) {
  const meFirebaseState = useMeStore(state => state.meFirebaseState)

  const { isPhone } = useMedia()

  return (
    <styleds.container>
      <styleds.lineButton
        css={css`
          right: ${!isPhone ? '16px' : '1'};
          bottom: ${!isPhone ? '2%' : '0'};
        `}
      >
        <a
          href={props.lineLink}
          target={'_blank'}
          rel='noreferrer'
        >
          <img
            src='/futuresai/index/line.png'
            css={css`
              width: 64px;
            `}
          />
        </a>
        {!isPhone && '聯繫客服'}
      </styleds.lineButton>

      {!meFirebaseState && (
        <styleds.loginCard>
          <styleds.logo src={props.faviconUrl} />
          <GoogleLoginView />
          <p>請使用Google帳號登入</p>
        </styleds.loginCard>
      )}
      {meFirebaseState && (
        <styleds.loginCard>
          <UnregisteredView product={props.product} />
        </styleds.loginCard>
      )}
    </styleds.container>
  )
})

const googleButtonStyled = {
  button: styled.div`
    ${fill_horizontal_all_center};
    ${fontWeight500};
    padding: 0px 16px;
    width: 240px;
    height: 40px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #aaaaaa;
    color: #7b7b7b;
    gap: 4px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background-color: #f1f1f1;
      width: 248px;
      height: 40px;
      font-size: 16.8px;
      transition: 0.3s;
    }
  `,
  logo: styled.img`
    width: 32px;
  `,
}

const infoStyleds = {
  itemTitle: styled.p`
    ${fill_vertical_all_center};
    width: 120px;
    height: 32px;
    background-color: #5d7c9daa;
    border-radius: 5px;
    ${fontWeight500};
    margin: 0px;
  `,
  item: styled.p`
    ${fill_vertical_all_center};
    height: 32px;
    font-size: 16px;
    ${fontWeight500};
    margin: 4px;
  `,
  logoutButton: styled.div`
    ${fill_vertical_all_center};
    width: 260px;
    height: 32px;
    background-color: #ca4b4b;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #da5b5b;
    }
  `,
  toolTipContent: styled.div`
    ${fill_vertical_all_center};
    height: 80px;
    width: 260px;
    background-color: #ca4b4b66;
    border-radius: 5px;
    margin-bottom: 32px;
    > p {
      margin: 8px;
    }
  `,
}

const styleds = {
  container: styled.div`
    position: fixed;
    z-index: 1000;
    ${fill_vertical_all_center};
    background-image: linear-gradient(90deg, #ffffff11, #ffffff11),
      url('opkevin/background-default.png');
    background-size: cover;
    padding: 8px;
  `,
  loginCard: styled.div`
    ${fill_vertical_all_center};
    width: 336px;
    height: 560px;
    background: linear-gradient(85deg, #ffffff33, #ffffff55);
    border-radius: 16px;
    animation: ${fadeIn} 2s;
    gap: 72px;
  `,
  lineButton: styled.div`
    display: flex;
    position: fixed;
    flex-direction: column;
    z-index: 2;
  `,
  logo: styled.img`
    width: 224px;
    padding: 16px;
    border-radius: 5px;
    background-color: #5d7c9d55;
  `,
}
