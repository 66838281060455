import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { forEach } from 'lodash'
import React, { memo, useEffect, useMemo, useState } from 'react'
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  ReferenceLine,
  AreaChart,
  Area,
  ReferenceLineProps,
  ReferenceDot,
  ReferenceArea,
} from 'recharts'
import { Tooltip } from 'recharts-new'
import { useSnapshot } from 'valtio'
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss'
import { optionAnalyzeStore } from './optionAnalyze/optionAnalyzeStore'
import { useOptionSimpleProfit } from './optionAnalyze/useOptionSimpleProfit'

type ChartDatum = {
  price: number
  profit: number
}

export const OptionChart = memo<ReactProps>(function OptionChart() {
  const state = useSnapshot(optionAnalyzeStore)

  const atTheMoneyPrice = state.atTheMoneyPrice
  const startDateTime = state.contractStartDateTime
  const endDateTime = state.intradayEndTime

  /** 起始區間 > 結束區間 */
  const errorSelect = endDateTime <= startDateTime

  const callData = useOptionSimpleProfit().call
  const putData = useOptionSimpleProfit().put

  /** 動畫 depend on:契約代號、開始區間、結束區間 */
  const [updatedAnimation, setUpdatedAnimation] = useState(false)

  useEffect(() => {
    setUpdatedAnimation(true)
    setTimeout(() => {
      setUpdatedAnimation(false)
    }, 1000)
  }, [state.currentContract, state.contractStartDateTime, state.intradayEndTime])

  /** 目前有的報價所有履約價 */
  const extendStrikePrices = callData?.map(s => s.strikePrice)

  const data = useMemo(() => {
    return extendStrikePrices?.map(stp => {
      let cexpectedProfitability = 0
      let pexpectedProfitability = 0

      forEach(callData, s => {
        let intrinsicValue = 0
        const strikePrice = Number(s.strikePrice)

        intrinsicValue = Math.max(Number(stp) - strikePrice, 0) * s.changeLots
        cexpectedProfitability += intrinsicValue + s.positionCost / 50
      })

      forEach(putData, s => {
        let intrinsicValue = 0
        const strikePrice = Number(s.strikePrice)

        intrinsicValue = Math.max(strikePrice - Number(stp), 0) * s.changeLots
        pexpectedProfitability += intrinsicValue + s.positionCost / 50
      })

      const result: ChartDatum = {
        price: Number(stp),
        profit: (cexpectedProfitability * 50 + pexpectedProfitability * 50) / 10000,
      }

      return result
    })
  }, [callData, putData])

  /** x軸最小數值 */
  const domainMax = Math.max(...(data?.map(s => s.price) ?? [0]))
  /** x軸最大數值 */
  const domainMin = Math.min(...(data?.map(s => s.price) ?? [0]))

  //損益兩平損益與0軸的交點 若有多筆(2個交點以上)只顯示最小與最大值
  /** 損益平衡點 最小價格 */
  const breakEevenPriceMin = findZeroProfitPrices(data ?? [])[0]
  /** 損益平衡點 最大價格 */
  const breakEevenPriceMax = findZeroProfitPrices(data ?? [])[1]

  return (
    <styleds.container>
      {!data || data.length === 0 ? (
        <styleds.loadingView>
          {errorSelect ? '結束區間不得大於起始區間' : 'Loading...'}
        </styleds.loadingView>
      ) : (
        <ResponsiveContainer
          width='100%'
          height='100%'
          css={css`
            animation: ${updatedAnimation === true && fadeIn} 1.5s;
          `}
        >
          <AreaChart
            data={data}
            margin={{
              top: 8,
              right: 32,
              left: 8,
              bottom: 8,
            }}
          >
            <CartesianGrid
              strokeDasharray='2 2'
              stroke={'#555555'}
            />
            <XAxis
              dataKey='price'
              stroke={'#cccccc'}
              tick={{ fontSize: 14 }}
              tickMargin={8}
              type={'number'}
              domain={[domainMax, domainMin]}
              tickCount={(domainMax - domainMin) / 50 + 1}
            />
            <YAxis
              stroke={'#cccccc'}
              tick={{ fontSize: 14 }}
              tickMargin={8}
              type={'number'}
            />
            <ReferenceLine
              y={0}
              stroke={'#aaaaaa'}
            />
            <ReferenceLine
              x={atTheMoneyPrice}
              stroke={'#425fb1'}
              strokeWidth={2}
              strokeDasharray='1 1'
              //{...referenceLineProps}
            />
            <Area
              type='monotone'
              dataKey='profit'
              stroke='#ddcc00'
              fill='#00000000'
              strokeWidth={3}
              dot={false}
              isAnimationActive={false}
            />
            <ReferenceDot
              x={breakEevenPriceMax}
              y={0}
              r={4}
              fill='#ddcc00'
              stroke='#ffffff'
              strokeWidth={2}
              label={
                {
                  value: breakEevenPriceMax,
                  fill: '#cccccc',
                  position: 'inside',
                  fontSize: 12,
                  dx: 0,
                  dy: 20,
                } as any
              }
            />
            <ReferenceDot
              x={breakEevenPriceMin}
              y={0}
              r={4}
              fill='#ddcc00'
              stroke='#ffffff'
              strokeWidth={2}
              label={
                {
                  value: breakEevenPriceMin,
                  fill: '#cccccc',
                  position: 'inside',
                  fontSize: 12,
                  dx: 0,
                  dy: 20,
                } as any
              }
            />
            <Tooltip content={<CustomizedTooltip />} />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: #1a1a1a;
    // background-image: linear-gradient(30deg, #1a1a1afa, #1a1a1afa), url('daddy960/opkevin-logo.png');
    // background-position: center;
    // background-repeat: space;
    // background-size: 28%;
  `,
  loadingView: styled.div`
    ${fill_horizontal_all_center};
    background: linear-gradient(110deg, #1a1a1a 8%, #222222 28%, #1a1a1a 43%);
    border-radius: 10px;
    background-size: 200% 100%;
    animation: 2s shine linear infinite;
    @keyframes shine {
      to {
        background-position-x: -200%;
      }
    }
  `,
}

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

// const referenceLineProps: ReferenceLineProps = {
//   label: {
//     position: 'insideEnd',
//     value: '價平',
//     fill: '#aa0000',
//     fontSize: 14,
//   } as any,
// }

const CustomizedTooltip = ({ payload }: { payload?: any[] }) => {
  if (!payload || (payload && payload.length < 1)) return null
  const datum = payload[0].payload
  return (
    <div
      css={css`
        background-color: #353535aa;
        border-radius: 7px;
        padding: 2px 8px;
        & > p {
          padding: 0px;
          line-height: 14px;
        }
      `}
    >
      <p>標的物價格: {datum.price}</p>
      <p>{datum.profit?.toFixed(2)}(萬)</p>
    </div>
  )
}

/** 使用線性插值插值計算出穿過0點的價格 gpt寫的XD */
function findZeroProfitPrices(data: ChartDatum[]): string[] {
  const zeroProfitPrices: string[] = []
  for (let i = 1; i < data.length; i++) {
    const prevItem = data[i - 1]
    const currentItem = data[i]
    if (prevItem.profit * currentItem.profit < 0) {
      // 利润由正变负或由负变正，插值计算出利润为0时的价格
      const x0 = parseFloat(prevItem.price.toString())
      const x1 = parseFloat(currentItem.price.toString())
      const y0 = prevItem.profit
      const y1 = currentItem.profit
      const k = (y1 - y0) / (x1 - x0)
      const x = x0 - y0 / k
      zeroProfitPrices.push(x.toFixed())
    } else if (prevItem.profit === 0 && currentItem.profit !== 0) {
      // 当前价格为利润非0的价格，插值计算出前一个价格
      const x1 = parseFloat(currentItem.price.toString())
      const y1 = currentItem.profit
      const x0 = x1 - y1 / 0.0001 // 0.0001是一个足够小的数，避免除以0
      zeroProfitPrices.push(x0.toFixed())
    }
  }
  return zeroProfitPrices
}
