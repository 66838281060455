import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { proxy, useSnapshot } from 'valtio'
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss'

export const stockRatioStore = proxy({
  up: 0 as number,
  down: 0 as number,
  total: 0 as number,
})
export const StockRatioGraph = memo<ReactProps>(function StockRatioGraph() {
  const state = useSnapshot(stockRatioStore)

  const upWidth = (state.up / state.total) * 100
  const downWidth = (state.down / state.total) * 100
  return (
    <div
      css={css`
        ${fill_horizontal_all_center};
        gap: 2px;
        font-size: 14px;
      `}
    >
      <styleds.rationContent
        width={upWidth < 8 ? 8 : upWidth}
        fill1='#cc272f'
        fill2='#fb5656'
      >
        {state.up}家
      </styleds.rationContent>

      <styleds.rationContent
        width={downWidth < 8 ? 8 : downWidth}
        fill1='#72c54a'
        fill2='#acff66bb'
      >
        {state.down}家
      </styleds.rationContent>
    </div>
  )
})

const styleds = {
  container: styled.div`
    ${fill_horizontal_all_center};
    height: 32px;
    gap: 2px;
    font-size: 14px;
  `,
  rationContent: styled.div<{ width: number; fill1: string; fill2: string }>`
    ${fill_horizontal_all_center};
    width: ${props => props.width}%;
    background: linear-gradient(100deg, ${props => props.fill1}, ${props => props.fill2});
    border-radius: 3px;
    height: 28px;
    white-space: nowrap;
  `,
}
